// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class ApprovalGroupService {

    index() {
        return axios.get(API_URL + 'approval-group', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'approval-group/'+id, { headers: authHeader() })
    }
    store(item) {
        return axios.post(API_URL + 'approval-group', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(API_URL + 'approval-group/'+item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(API_URL + 'approval-group/'+item.id, { headers: authHeader() })
    }
}

export default new ApprovalGroupService()
